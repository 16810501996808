
import { Options, Vue } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ShopifyController } from "../../controllers/ShopifyController";
import { ShopifyTokenData } from "@/domain/entities/Shopify";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import { CLIENT_ERROR } from "@/app/infrastructures/misc/Constants";
import copy from "copy-text-to-clipboard";

@Options({
  components: {},
})
export default class Shopify extends Vue {
  mounted() {
    this.generateLoad();
  }

  copyClientId() {
    if (this.shopifyData.clientKey && this.status !== 'expired') {
      this.setInfo("copied");
      this.wordingCopied = "Client ID successfully copied";
      copy(this.shopifyData.clientKey);
    }
  }

  copyClientSecreet() {
    if (this.shopifyData.clientSecret && this.status !== 'expired') {
      this.setInfo("copied");
      this.wordingCopied = "Client Secret successfully copied";
      copy(this.shopifyData.clientSecret);
    }
  }

  setInfo(status: string) {
    this.info = true;
    this.status = status;
  }

  closeInfo() {
    this.info = false;
  }

  info = true;
  status = "";
  wordingCopied = "";
  get prompt() {
    return {
      success: {
        icon: "check-circle",
        iconPosition: "left",
        text: "Token successfully created, please use it to connect Shopify plugin",
        color: "green-lp-400",
        background: "green-lp-500",
        iconColor: "green-lp-400"
      },
      expired: {
        icon: "warning-circle",
        iconPosition: "left",
        text: "Client ID & Client Secret expired, please generate again",
        color: "orange-lp-300",
        background: "yellow-lp-400",
        iconColor: "yellow-lp-500"
      },
      copied: {
        icon: "",
        iconPosition: "right",
        text: this.wordingCopied,
        color: "white",
        background: "gray-lp-300",
        iconColor: "white"
      },
    };
  }

  shopifyData: ShopifyTokenData = new ShopifyTokenData();
  isError = false;
  isButtonGenerateClicked = false;

  async generateClicked() {
    this.isButtonGenerateClicked = true;
    this.generateProcess();
  }

  async generateLoad() {
    this.isButtonGenerateClicked = false;
    this.generateProcess();
  }

  async generateProcess() {
    this.isError = false;
    MainAppController.setLoadingModal(true);
    try {
      const method = this.isButtonGenerateClicked ? "post" : "get";
      const data = await ShopifyController.generateToken(method);
      this.info = true;
      if (this.statusSuccess(data.isExpiredClientCredential, data.clientKey, data.clientSecret)) {
        this.status = "success";
      } else if (this.noStatus(data.isExpiredClientCredential, data.clientKey, data.clientSecret)){
        this.status = "";
      } else {
        this.status = "expired";
      }
      this.shopifyData = data;
    } catch (error) {
      this.isError = true;
      if (this.isButtonGenerateClicked) {
        MainAppController.showErrorMessage(
          new ErrorMessageEntities({
            type: CLIENT_ERROR,
            message: "There was an error in our system, <br> please generate again",
            title: "Token Generated Failed!"
          })
        );
      }
    }
    MainAppController.setLoadingModal(false);
  }

  statusSuccess(isExpiredClientCredential: boolean, clientKey: string, clientSecret: string) {
    return !isExpiredClientCredential && clientKey && clientSecret
  }

  noStatus(isExpiredClientCredential: boolean, clientKey: string, clientSecret: string) {
    return !isExpiredClientCredential && !clientKey && !clientSecret;
  }

}
